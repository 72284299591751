import { mapGetters, mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      menuQty: (state) => state.menu.qty,
    }),
    ...mapGetters(["total", "menuBlock", "itemsBlock"]),
    products() {
      if (this.menuBlock) {
        const allMenu =
          this.menuBlock?.body?.find((b) => b.name === "items").value || [];
        return allMenu
          .filter((product) => this.menuQty[product.name])
          .map((product) => ({
            ...product,
            count: this.menuQty[product.name],
          }));
      }
      if (this.itemsBlock) {
        const allMenu =
          this.itemsBlock?.body?.find((b) => b.name === "items").value || [];
        return allMenu
          .filter((product) => this.menuQty[product.name])
          .map((product) => ({
            ...product,
            count: this.menuQty[product.name],
          }));
      }
      // const allMenu =
      //   this.menuBlock?.body?.find((b) => b.name === "items").value || [];
      // return allMenu
      //   .filter((product) => this.menuQty[product.name])
      //   .map((product) => ({ ...product, count: this.menuQty[product.name] }));
    },
  },
};
