<template>
  <div class="check-products">
    <div
      v-for="product in products"
      :key="product.productName"
      class="check-products__item"
    >
      <h3 class="check-products__name">{{ product.productName }}</h3>

      <div class="check-products__calculation">
        <div class="check-products__money">
          <money :value="product.cost" />
        </div>
        <span></span>
        <div class="check-products__count">
          {{ product.quantity }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["products"],
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/components/check-products.scss";
</style>
