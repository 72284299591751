<template>
  <div v-if="payResult" class="custom-amount-form menu-form">
    <Header :withMerchant="false" />
    <section class="main successful-payment">
      <OrderCheck
        :invoiceNumber="payResult.invoiceNumber || payResult.orderNumber"
        :showConnect="connect"
        :paymentResult="paymentResult"
        :products="payResult.saleInfo"
        :total="payResult.totalAmount"
      />
    </section>
  </div>
</template>

<script>
import OrderCheck from "@/components/checkout/checkout-result/OrderCheck";
import getProductsCountInfo from "@/mixins/getProductsCountInfo";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { Block } from "@/constants";
import { MENU_CLEAR } from "@/store/mutations.type";
import { getBlock } from "@/store/modules/menu";
import checkoutResult from "@/mixins/checkoutResult";
import getMenuInfo from "@/mixins/getMenuInfo";
import { GET_INVOICE } from "@/store/actions.type";
import setRoute from "@/mixins/setRoute";

export default {
  mixins: [getMenuInfo, getProductsCountInfo, checkoutResult, setRoute],
  components: {
    OrderCheck,
  },
  data: () => ({
    payResult: null,
  }),
  computed: {
    ...mapGetters(["pageTemplate", "route"]),
    connect() {
      return getBlock(
        this.pageTemplate,
        this.route.current,
        Block.MerchantContactsBlock
      );
    },
  },
  methods: {
    ...mapMutations({
      menuClear: MENU_CLEAR,
    }),
    ...mapActions({
      getInvoice: GET_INVOICE,
    }),
    replaceUrl(invoiceId) {
      this.$router.push({
        query: { invoice_id: invoiceId },
      });
    },
  },
  async beforeMount() {
    const { invoice_id: invoiceId } = this.$route.query;
    if (invoiceId) {
      await this.getInvoice(invoiceId);
      this.payResult = this.$store.getters["invoiceData"];
      this.replaceUrl(this.payResult?.internal_id);
    }
  },
  mounted() {
    this.replaceUrl(this.payResult?.internal_id);
    if (this.$route.query?.invoice_id === "") this.$router.push("/");

    Object.keys(localStorage)
      .filter((key) => key.split("_")[0] === this.pagePath)
      .forEach((item) => localStorage.removeItem(item));
    this.menuClear();
  },
};
</script>
